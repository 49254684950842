

export const developmentConfig = {
  baseUrl: 'http://localhost:8070/fhir-viewer',
  fhirDataUrl: 'http://localhost:8089/fhir'
}

export const productionConfig = {
  baseUrl: 'https://patient-portal-api.itea.sandbox.identos.ca/fhir-viewer',
  fhirDataUrl: 'https://cdr-rsa.itea.sandbox.identos.ca/fhir'
}

export const appConfig = productionConfig
  