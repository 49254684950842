// import { useTranslation } from "react-i18next";

function EncounterDetail({ data, observationData }) {
  // const { t } = useTranslation();
  // example of how to define these items, to be tested against correct API
  const practitioner = data?.participant?.[0]?.individual?.display;
  const type = data?.type?.[0]?.coding?.[0]?.display;

  const observations = observationData?.entry;

  return (
    <div className="encounter-detail">
      <h4>Practitioner</h4>
      <h3>{practitioner}</h3>
      <h4>Type</h4>
      <h3>{type}</h3>
      {observations && <h2>Observations</h2>}
      {observations?.map((obs, i) => {
        const title = obs?.resource.code?.coding?.[0]?.display;
        const description = obs.resource.valueQuantity;
        return (
          <p key={i}>
            {title}: <span className="bold-text">{`${description.value} ${description.unit}`}</span>
          </p>
        );
      })}
    </div>
  );
}

export default EncounterDetail;
